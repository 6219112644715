import type { GridColumnVisibilityModel } from '@mui/x-data-grid-premium';

export const COLUMNS = Object.freeze({
	NAME: 'name',
	OWNERSHIP_STRUCTURE: 'ownership_structure',
	EDS_STAFFED: 'num_eds',
	RESIDENCY_EDS: 'num_residency_eds',
	FREESTANDING_EDS: 'num_freestanding_eds',
});

export const INITIAL_COLUMNS: GridColumnVisibilityModel = {
	[COLUMNS.NAME]: true,
	[COLUMNS.OWNERSHIP_STRUCTURE]: true,
	[COLUMNS.EDS_STAFFED]: true,
	[COLUMNS.RESIDENCY_EDS]: true,
	[COLUMNS.FREESTANDING_EDS]: true,
};
