import React, {
	useMemo,
	createContext,
	useState,
	useContext,
	useCallback,
} from 'react';

import { type GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { COLUMNS } from './ColumnMenu';

interface FilterState {
	professions: string[];
}

interface FiltersContextValue {
	rawColumns: GridColumnVisibilityModel;
	rawFilters: FilterState;
	appliedColumns: GridColumnVisibilityModel;
	appliedFilters: FilterState;
	filtersCount: { [id: string]: number };
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	apiFilters: { [k: string]: any }[];
	setRawFilters: React.Dispatch<React.SetStateAction<FilterState>>;
	setRawColumns: React.Dispatch<
		React.SetStateAction<GridColumnVisibilityModel>
	>;
	applyFilters: (nv?: FilterState) => void;
	discardFilters: () => void;
	resetFilters: () => void;
	applyColumns: (nv?: GridColumnVisibilityModel) => void;
	discardColumns: () => void;
	resetColumns: () => void;
}

const FilterContext = createContext<FiltersContextValue>(undefined!);

export const INITIAL_FILTERS: FilterState = {
	professions: [],
};

export const INITIAL_COLUMNS: GridColumnVisibilityModel = {
	[COLUMNS.FACILITY]: true,
	[COLUMNS.TITLE]: true,
	[COLUMNS.PROFESSION]: true,
	[COLUMNS.PUBLISHED_DATE]: false,
	[COLUMNS.RECRUITER]: true,
	[COLUMNS.EXPIRATION_DATE]: true,
	// [COLUMNS.IMPRESSIONS]: true,
};

export interface FiltersProviderProps extends React.PropsWithChildren {
	defaultColumns?: GridColumnVisibilityModel;
}

export const FiltersProvider = ({
	defaultColumns,
	children,
}: FiltersProviderProps) => {
	const [rawColumns, setRawColumns] = useState<GridColumnVisibilityModel>(
		defaultColumns || INITIAL_COLUMNS,
	);
	const [appliedColumns, setAppliedColumns] =
		useState<GridColumnVisibilityModel>(rawColumns);
	const [rawFilters, setRawFilters] = useState<FilterState>(INITIAL_FILTERS);
	const [appliedFilters, setAppliedFilters] = useState<FilterState>(rawFilters);

	const apiFilters = useMemo(() => {
		return [
			appliedFilters.professions.length
				? {
						job_posting: {
							professions: {
								profession: {
									_in: appliedFilters.professions.flat(),
								},
							},
						},
				  }
				: {},
		].filter((el) => !!Object.keys(el).length);
	}, [appliedFilters]);

	const applyFilters = useCallback(
		(nv?: FilterState) => {
			if (nv) {
				setRawFilters(nv);
				setAppliedFilters(nv);
			} else {
				setAppliedFilters(rawFilters);
			}
		},
		[rawFilters, setAppliedFilters],
	);

	const resetFilters = useCallback(() => {
		setRawFilters(INITIAL_FILTERS);
		setAppliedFilters(INITIAL_FILTERS);
	}, [setRawFilters, setAppliedFilters]);

	const discardFilters = useCallback(() => {
		setRawFilters(appliedFilters);
	}, [appliedFilters, setRawFilters]);

	const applyColumns = useCallback(
		(nv?: GridColumnVisibilityModel) => {
			if (nv) {
				setRawColumns(nv);
				setAppliedColumns(nv);
			} else {
				setAppliedColumns(rawColumns);
			}
		},
		[rawColumns, setAppliedColumns],
	);

	const resetColumns = useCallback(() => {
		setRawColumns(INITIAL_COLUMNS);
		setAppliedColumns(INITIAL_COLUMNS);
	}, [setRawColumns, setAppliedColumns]);

	const discardColumns = useCallback(() => {
		setRawColumns(appliedColumns);
	}, [appliedColumns, setRawColumns]);

	const filtersCount: { [key: string]: number } = useMemo(() => {
		return Object.entries(appliedFilters).reduce(
			(tot, [k, v]) => ({
				...tot,
				[k]: isObject(v)
					? Object.values(v).filter((el) => el).length
					: isArray(v)
					? v.length
					: v !== null
					? 1
					: 0,
			}),
			{},
		);
	}, [appliedFilters]);

	return (
		<FilterContext.Provider
			value={{
				rawColumns,
				setRawColumns,
				appliedColumns,
				rawFilters,
				setRawFilters,
				apiFilters,
				appliedFilters,
				applyFilters,
				discardFilters,
				resetFilters,
				applyColumns,
				discardColumns,
				resetColumns,
				filtersCount,
			}}
		>
			{children}
		</FilterContext.Provider>
	);
};

export const useFilterContext = () => useContext(FilterContext);
