import React, {
	useState,
	useCallback,
	useLayoutEffect,
	useEffect,
	useRef,
	memo,
} from 'react';

import { InfoOutlined } from '@mui/icons-material';
import Search from '@mui/icons-material/Search';
import {
	Box,
	type SxProps,
	type Theme,
	Typography,
	TextField,
	useTheme,
	Stack,
	useMediaQuery,
	InputAdornment,
} from '@mui/material';

import { useDebounce } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';

const keyframes = {
	'@keyframes landing-load': {
		'0%': {
			transform: 'translateY(60px)',
			opacity: 0,
		},
		'100%': {
			transform: 'translateY(0px)',
			opacity: 1,
		},
	},
};

const generateKeyframes = (timing) => ({
	...keyframes,
	animation: `landing-load 600ms ease-in-out ${timing}ms`,
	animationFillMode: 'forwards',
	transform: 'translateY(60px)',
	opacity: 0,
});

const BG = () => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				position: 'absolute',
				width: '100vw',
				left: '50%',
				right: '50%',
				ml: '-50vw',
				mr: '-50vw',
				top: 0,
				bottom: 0,
				filter: {
					gridBreak: 'none',
				},
			}}
		>
			<Box
				sx={{
					width: '100%',
					height: { xs: 'calc(98% - 1vw)', gridBreak: '100%' },
					bgcolor: theme.palette.light4.main,
				}}
			/>
		</Box>
	);
};

interface HeroProps {
	sx?: SxProps<Theme>;
	searchTerm?: string;
	onTextChange: (newValue: string) => void;
}

const HeroBG = memo(BG);

const Hero = ({ onTextChange, sx, searchTerm = '' }: HeroProps) => {
	const theme = useTheme();
	const [heroRef, setRef] = useState<HTMLDivElement | null>(null);
	const [search, setSearch] = useState(searchTerm);
	const debouncedSearch = useDebounce(search, 1000);
	const [initialized, setInitialized] = useState(false);
	const heroNode = useCallback((node) => node && setRef(node), []);
	const searchRef = useRef<HTMLDivElement>(null);

	const handleTextChange = useCallback(
		(ev) => {
			setSearch(ev.target.value);
		},
		[setSearch],
	);

	const isMobile = useMediaQuery(theme.breakpoints.down('gridBreak'), {
		noSsr: true,
	});
	const isSm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
	const heroPadding = 64 + 56 + (isSm ? 30 : 48);

	useLayoutEffect(() => {
		const handleScroll = () => {
			if (!heroRef || !searchRef.current || !isMobile) {
				return;
			} else if (!isMobile) {
				searchRef.current.style.top = '0px';
				return;
			}

			const searchThreshold = isSm ? 20 : 60;
			const threshold = heroRef?.clientHeight - (heroPadding + searchThreshold);

			if (
				document.body.scrollTop > threshold ||
				document.documentElement.scrollTop > threshold
			) {
				searchRef.current.style.top = `${searchThreshold}px`;
			} else {
				searchRef.current.style.top = '0px';
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isMobile, isSm, heroRef, theme, heroPadding]);

	useEffect(() => {
		// This exists to prevent the initial search from triggering a second search on mount since we already hit the API with the initial search term
		if (!initialized) {
			setInitialized(true);
			return;
		}
		onTextChange(debouncedSearch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearch, onTextChange]);

	return (
		<Box
			ref={heroNode}
			component='section'
			sx={combineSx(
				{
					position: {
						gridBreak: 'relative',
						xs: 'sticky',
					},
					mt: {
						xs: '-64px',
						mobileMenu: '-104px',
					},
					pt: {
						xs: '64px',
						mobileMenu: '104px',
					},
					pb: {
						gridBreak: 0,
						xs: 6,
					},
					top: {
						gridBreak: '0',
						xs: `calc(-${heroRef?.clientHeight || 0}px + ${heroPadding}px)`,
					},
					px: {
						gridBreak: 3,
						xs: 0,
					},
				},
				sx,
			)}
		>
			<HeroBG />
			<Box
				sx={{
					position: {
						gridBreak: 'relative',
						xs: 'sticky',
					},
					height: '100%',
					[theme.breakpoints.between('md', 'gridBreak')]: {
						display: 'flex',
						justifyContent: 'center',
					},
				}}
			>
				<Box
					sx={{
						pt: {
							xs: 4,
							sm: 8,
							// Position in true center by offsetting the NavBar
							// Use md here, not mobileMenu
							md: '-104px',
						},
						pb: {
							gridBreak: 0,
							sm: 6,
							xs: 0,
						},
						height: {
							xs: '100%',
							md: 'auto',
						},
						position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						alignItems: {
							sm: 'left',
						},
						maxWidth: { gridBreak: '800px', xs: '900px' },
					}}
				>
					<Stack
						direction={{
							mobileMenu: 'row',
							xs: 'column-reverse',
						}}
						alignItems='center'
						sx={{
							mb: {
								sm: 5,
								xs: 4,
							},
							alignItems: 'flex-start',
						}}
					>
						<Typography
							component='h1'
							variant='h2'
							sx={{
								textAlign: 'left',
								flex: '0 0 auto',
								maxWidth: { xs: '540px', lg: '620px' },
								...generateKeyframes(0),
							}}
						>
							State of the EM Employer Market
						</Typography>
					</Stack>
					<Box
						sx={{
							display: 'flex',
							mb: {
								sm: 5,
								xs: 5,
							},
							textAlign: {
								sm: 'left',
							},
							flex: '0 0 auto',
							color: 'text.icon',
							maxWidth: { xs: 'none', lg: '650px' },
							...generateKeyframes(200),
						}}
					>
						<InfoOutlined fontSize='medium' sx={{ mr: 0.5, mb: '-2px' }} />
						<Typography
							variant='h6'
							component='p'
							sx={{
								fontWeight: { xs: 400, sm: 600 },
							}}
						>
							This information is updated continuously and reflects the most
							recent data available.
						</Typography>
					</Box>
					<Box
						sx={{
							...generateKeyframes(400),
						}}
					>
						<TextField
							id='input-employer-search'
							ref={searchRef}
							placeholder='Search by employer name'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<Search
											sx={{
												display: { xs: 'block', gridBreak: 'none' },
												ml: 1.5,
											}}
										/>
									</InputAdornment>
								),
								sx: {
									pl: 1,
									borderRadius: '999px !important',
									bgcolor: 'white',
									display: {
										gridBreak: 'none',
										xs: 'flex',
									},
								},
							}}
							value={search}
							onChange={handleTextChange}
							variant='outlined'
							sx={{
								width: isMobile ? '100%' : '600px',
								maxWidth: '100%',
								transition: 'top 0.4s',
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Hero;
