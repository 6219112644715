import { InMemoryCache, type Reference } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { parseISO, parseJSON } from 'date-fns';

import { AccountType } from '@ivy/constants/account';
import {
	ExperienceLevel,
	PAYMENTRATEDISPLAY,
	PaymentRatePeriod,
	ProfDegree,
} from '@ivy/constants/clinician';
import { formatFacilityType } from '@ivy/lib/formatting/facility';
import { formatWage } from '@ivy/lib/formatting/posting';
import { getToday, isDateBeforeToday } from '@ivy/lib/helpers/date';

// TODO: parse dates + datetimes automatically
// Better to do with field policies, but won't work on no-cache
// https://github.com/apollographql/apollo-client/issues/8857
// https://stackoverflow.com/questions/66085887/how-can-you-retrieve-a-date-field-in-apollo-client-from-a-query-as-a-date-and-no

const accountCacheProps = {
	fields: {
		isClinician: {
			read(_, { readField }) {
				return readField('type') === AccountType.CLINICIAN;
			},
		},
		isOrgUser: {
			read(_, { readField }) {
				return readField('type') === AccountType.ORG;
			},
		},
	},
};

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				search_relevant_salary_report: {
					...offsetLimitPagination(['where', 'order_by', 'args']),
					read(existing, { args }) {
						if (args?.offset == null || args?.limit == null) {
							return existing;
						}
						// A read function should always return undefined if existing is
						// undefined. Returning undefined signals that the field is
						// missing from the cache, which instructs Apollo Client to
						// fetch its value from your GraphQL server.
						return (
							existing && existing.slice(args.offset, args.offset + args.limit)
						);
					},
				},
				search_relevant_job_posting: {
					...offsetLimitPagination(['where', 'order_by', 'args']),
					read(existing, { args }) {
						if (args?.offset == null || args?.limit == null) {
							return existing;
						}
						// A read function should always return undefined if existing is
						// undefined. Returning undefined signals that the field is
						// missing from the cache, which instructs Apollo Client to
						// fetch its value from your GraphQL server.
						return (
							existing && existing.slice(args.offset, args.offset + args.limit)
						);
					},
				},
				search_candidate: {
					...offsetLimitPagination(['where', 'order_by', 'args']),
					read(existing, { args }) {
						if (args?.offset == null || args?.limit == null) {
							return existing;
						}
						// A read function should always return undefined if existing is
						// undefined. Returning undefined signals that the field is
						// missing from the cache, which instructs Apollo Client to
						// fetch its value from your GraphQL server.
						return (
							existing && existing.slice(args.offset, args.offset + args.limit)
						);
					},
				},
				search_fsmb_bio: {
					keyArgs: ['args', 'where', 'order_by'],
					merge(existing, incoming, { args }) {
						const merged = existing ? existing.slice(0) : [];
						if (args?.offset == null || args?.limit == null) {
							return incoming;
						}
						// Insert the incoming elements in the right places, according to args.
						const end = args.offset + Math.min(args.limit, incoming.length);
						for (let i = args.offset; i < end; ++i) {
							merged[i] = incoming[i - args.offset];
						}
						return merged;
					},
				},
				search_facility_by_prefix: {
					keyArgs: ['args', 'where', 'order_by'],
					merge(existing, incoming, { args }) {
						const merged = existing ? existing.slice(0) : [];
						if (args?.offset == null || args?.limit == null) {
							return incoming;
						}
						// Insert the incoming elements in the right places, according to args.
						const end = args.offset + Math.min(args.limit, incoming.length);
						for (let i = args.offset; i < end; ++i) {
							merged[i] = incoming[i - args.offset];
						}
						return merged;
					},
					read(existing, { args, variables }) {
						// A query might specify offset + limit but intend to do infinite scrolling
						if (!existing || variables?.infiniteScrolling) {
							return existing;
						}
						// Adapt if the query specifies any combination of offset and limit
						const offset = args?.offset ?? 0;
						// Max limit if no offset specified is from the offset to the end of the array
						const limit = args?.limit ?? existing.length - offset;
						return existing.slice(offset, offset + limit);
					},
				},
				search_org_market: {
					...offsetLimitPagination(['where', 'order_by', 'args']),
					read(existing, { args }) {
						if (args?.offset == null || args?.limit == null) {
							return existing;
						}
						return (
							existing && existing.slice(args.offset, args.offset + args.limit)
						);
					},
				},
				review: {
					...offsetLimitPagination(['where', 'order_by']),
					read(existing, { args }) {
						if (args?.offset == null || args?.limit == null) {
							return existing;
						}
						// A read function should always return undefined if existing is
						// undefined. Returning undefined signals that the field is
						// missing from the cache, which instructs Apollo Client to
						// fetch its value from your GraphQL server.
						return (
							existing && existing.slice(args.offset, args.offset + args.limit)
						);
					},
				},
			},
		},
		access_info: {
			fields: {
				lastAccessDate: {
					read(_, { readField }) {
						const fld = readField('last_access') as string | null | undefined;
						return fld ? parseJSON(fld) : null;
					},
				},
			},
		},
		account: accountCacheProps,
		current_account: accountCacheProps,
		candidate: {
			fields: {
				lastAppliedDate: {
					read(_, { readField }) {
						const fld = readField('last_applied') as string | null | undefined;
						return fld ? parseJSON(fld) : null;
					},
				},
			},
		},
		org_user: {
			fields: {
				lastViewedCandidatesDate: {
					read(_, { readField }) {
						const fld = readField('last_viewed_candidates') as
							| string
							| null
							| undefined;
						return fld ? parseJSON(fld) : null;
					},
				},
			},
		},
		clinician: {
			fields: {
				isAPP: {
					read(_, { readField }) {
						const profDegree = readField('prof_degree') as ProfDegree;
						return [ProfDegree.PA, ProfDegree.NP].includes(profDegree);
					},
				},
				// Set overriding merge policies to avoid warnings from Apollo from CompleteProfile page when these are changed
				training: {
					merge(_, incoming) {
						return incoming;
					},
				},
				appCerts: {
					merge(_, incoming) {
						return incoming;
					},
				},
				specialties: {
					merge(_, incoming) {
						return incoming;
					},
				},
				sp_exp: {
					merge(_, incoming, { readField }) {
						// Show highest experience first
						const order = Object.values(ExperienceLevel).reverse();
						return incoming.slice().sort((a, b) => {
							const aLevel = readField('level', a) as ExperienceLevel;
							const bLevel = readField('level', b) as ExperienceLevel;
							const aIndex = aLevel ? order.indexOf(aLevel) : 0;
							const bIndex = bLevel ? order.indexOf(bLevel) : 0;
							return aIndex - bIndex;
						});
					},
				},
			},
		},
		job_application: {
			fields: {
				createdAtDate: {
					read(_, { readField }) {
						const fld = readField('created_at') as string | null | undefined;
						return fld ? parseJSON(fld) : null;
					},
				},
			},
		},
		AS22DEM: {
			fields: {
				rollups: {
					read(_, { readField }) {
						const names = new Set<string>();
						const units = (readField('units') as Reference[]) || [];
						units.forEach((unit) => {
							const unitName = readField('UNAME', unit) as string;
							if (unitName) {
								names.add(unitName);
							}
							const par = readField('parent_aha_facility', unit) as Reference;
							if (!par) {
								return;
							}
							const parName = readField('MNAME', par) as string;
							if (!parName) {
								return;
							}
							names.add(parName);
						});
						return Array.from(names).sort((a, b) => a.localeCompare(b));
					},
				},
			},
		},
		facility: {
			fields: {
				facilityType: {
					read(_, { readField }) {
						const cms = readField('cms_facility') as Reference;
						const pos = readField('cms_facility_pos') as Reference;
						const enrollment = readField(
							'cms_facility_enrollment',
						) as Reference;
						return formatFacilityType({
							cmsHospitalType: cms ? readField('hospital_type', cms) : null,
							posProviderSubtype: pos
								? readField('provider_subtype', pos)
								: null,
							enrollmentProviderType: enrollment
								? readField('provider_type_code', enrollment)
								: null,
							freestanding: readField('freestanding_er'),
							adultTraumaLvl: readField('adult_trauma_lvl'),
						});
					},
				},
				immediateNeeds: {
					read(_, { args, readField }) {
						const featured = readField({
							fieldName: 'featured_for_prof',
							args: args || undefined,
						});
						const numPostings = readField({
							fieldName: 'num_postings_for_prof',
							args: args || undefined,
						});
						return featured || !!numPostings;
					},
				},
			},
		},
		facility_contract: {
			fields: {
				lastAppliedDate: {
					read(_, { readField }) {
						const fld = readField('last_applied') as string | null | undefined;
						return fld ? parseJSON(fld) : null;
					},
				},
				org: {
					// We don't specify the id of the org, so we need to make a custom merge function to avoid warnings
					merge(existing, incoming) {
						return incoming;
					},
				},
			},
		},
		fsmb_bio: {
			// This is necessary for caching as well as preventing an infinite loop bug where two active queries
			// request the same field and apollo cache infinitely loops the fetch request since it cannot
			// cache it properly
			// https://github.com/apollographql/apollo-client/issues/10992
			keyFields: ['fid'],
		},
		job_posting: {
			fields: {
				startOrTodayDate: {
					read(_, { readField }) {
						const fld = readField('start_date') as string | null | undefined;
						if (!fld) {
							return null;
						}
						const dt = parseISO(fld);
						return isDateBeforeToday(dt) ? getToday() : dt;
					},
				},
				createdAtDate: {
					read(_, { readField }) {
						const fld = readField('created_at') as string | null | undefined;
						return fld ? parseJSON(fld) : null;
					},
				},
				lastModifiedDate: {
					read(_, { readField }) {
						const fld = readField('last_modified') as string | null | undefined;
						return fld ? parseJSON(fld) : null;
					},
				},
				wage: {
					read(_, { readField }) {
						return formatWage(
							readField('is_exact_rate'),
							readField('exact_rate'),
							readField('min_rate'),
							readField('max_rate'),
							PAYMENTRATEDISPLAY[readField('rate_period') as string],
							false,
						);
					},
				},
				wageTrunc: {
					read(_, { readField }) {
						if (!readField('has_rate')) {
							return '';
						}
						return formatWage(
							readField('is_exact_rate'),
							readField('exact_rate'),
							readField('min_rate'),
							readField('max_rate'),
							PAYMENTRATEDISPLAY[readField('rate_period') as string],
							readField('rate_period') === PaymentRatePeriod.YEAR,
						);
					},
				},
			},
		},
	},
	// typePolicies: {
	// 	Query: {
	// 		isAuthenticated: {
	// 			read() {
	// 				return isAuthenticatedVar();
	// 			}
	// 		},
	// 		currentAccount: {
	// 			read() {
	// 				return currentAccountVar();
	// 			}
	// 		}
	// 	}
	// }
});

export default cache;
