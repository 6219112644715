export enum OwnershipStructure {
	SINGLEPARTNER = 'SINGLEPARTNER',
	MULTIPARTNER = 'MULTIPARTNER',
	NATPARTNER = 'NATPARTNER',
	HEALTHSYSTEM = 'HEALTHSYSTEM',
	LARGECORP = 'LARGECORP',
	VA = 'VA',
	INDIAN = 'INDIAN',
	MILITARY = 'MILITARY',
}

export const ORGNIZATION_PLACEHOLDER_IMAGE =
	'https://assets.ivyclinicians.io/images/building-placeholder.jpg';

export const OWNERSHIP_DESCRIPTION = Object.freeze({
	[OwnershipStructure.NATPARTNER]: 'National Clinician Partnership',
	[OwnershipStructure.MULTIPARTNER]: 'Regional Clinician Partnership',
	[OwnershipStructure.SINGLEPARTNER]: 'Single Site Clinician Partnership',
	[OwnershipStructure.LARGECORP]: 'Corporation',
	[OwnershipStructure.HEALTHSYSTEM]: 'Health System',
	[OwnershipStructure.VA]: 'Veterans Affairs',
	[OwnershipStructure.INDIAN]: 'Indian Health Service',
	[OwnershipStructure.MILITARY]: 'Military Health System',
});

export const OWNERSHIP_DESCRIPTION_SUCCINT = Object.freeze({
	[OwnershipStructure.NATPARTNER]: 'Partnership',
	[OwnershipStructure.MULTIPARTNER]: 'Partnership',
	[OwnershipStructure.SINGLEPARTNER]: 'Partnership',
	[OwnershipStructure.LARGECORP]: 'Corporation',
	[OwnershipStructure.HEALTHSYSTEM]: 'Health System',
	[OwnershipStructure.VA]: 'VA',
	[OwnershipStructure.INDIAN]: 'IHS',
	[OwnershipStructure.MILITARY]: 'Military',
});

export const EM_DASHBOARD_OWNERSHIP_LEGEND = Object.freeze({
	[OwnershipStructure.NATPARTNER]: 'Clinician partnership',
	[OwnershipStructure.MULTIPARTNER]: 'Clinician partnership',
	[OwnershipStructure.SINGLEPARTNER]: 'Clinician partnership',
	[OwnershipStructure.LARGECORP]: 'Corporation',
	[OwnershipStructure.HEALTHSYSTEM]: 'Health System',
	[OwnershipStructure.VA]: 'Government',
	[OwnershipStructure.INDIAN]: 'Government',
	[OwnershipStructure.MILITARY]: 'Government',
});
