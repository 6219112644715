import React, {
	useState,
	useCallback,
	useMemo,
	useEffect,
	memo,
	Suspense,
} from 'react';

import { useQuery } from '@apollo/client';
import { Search } from '@mui/icons-material';
import {
	Box,
	type SxProps,
	type Theme,
	TextField,
	InputAdornment,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { type GridSortModel } from '@mui/x-data-grid-premium';

import RouteLink from '@ivy/components/atoms/RouteLink';
import {
	GridSkeleton,
	TableSkeleton,
} from '@ivy/components/molecules/ListSkeletons';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { gql } from '@ivy/gql/types';
import { useDebounce } from '@ivy/lib/hooks';
import { useNotifyError } from '@ivy/lib/hooks';
import lazyRetry from '@ivy/lib/util/lazyRetry';
import { buildInternalLink } from '@ivy/lib/util/route';

import { type EmployerTableProps } from './EmployerTable';
import { INITIAL_COLUMNS } from './filters';

// Since we have multiple lazyRetry imports, we need to pass a unique key to each
const eGrid = lazyRetry(
	() => import('./EmployerGrid'),
	'EmployerList_EmployerGrid',
);
const eTable = lazyRetry(
	() => import('./EmployerTable'),
	'EmployerList_EmployerTable',
);

const DEFAULT_PAGE_SIZE = (restrict) => ({
	// 10 rows of 1 card
	xs: restrict ? 3 : 10,
	// 10 rows of 2 cards
	sm: (restrict ? 2 : 10) * 2,
	// 10 rows of 3 cards
	md: (restrict ? 2 : 10) * 3,
	desktop: 25,
});

// We're including inactive contracts in filters + connections
export const EmployerList_SearchEmployersQDoc = gql(/* GraphQL */ `
	query EmployerList_SearchEmployers(
		$limit: Int!
		$offset: Int!
		$orderBy: [search_org_market_result_order_by!]
		$search: String!
	) {
		search: search_org_market(
			limit: $limit
			offset: $offset
			order_by: $orderBy
			args: { search: $search }
		) {
			id
			market: org_market {
				id
				org {
					id
					slug
				}
				...EmployerCard_EmployerInfo
				...EmployerTable_EmployerInfo
			}
		}
		agg: search_org_market_aggregate(args: { search: $search }) {
			aggregate {
				count
			}
		}
	}
`);

const DEFAULT_ORDER_BY = [
	{
		org_market: {
			num_eds: 'desc',
		},
	},
	{
		org_market: {
			id: 'asc',
		},
	},
];

interface EmployerListProps {
	searchTerm?: string;
	onCompletedQuery?: (val) => void;
	onErrorQuery?: (val) => void;
	onTextChange?: (newValue: string) => void;
	tableProps?: Omit<
		EmployerTableProps,
		'onChangeSort' | 'employers' | 'loading' | 'filtering'
	>;
	disableInfiniteScroll?: boolean;
	hideSearchBar?: boolean;
	restrictMobileResults?: boolean;
	sx?: SxProps<Theme>;
}

const EmployerTable = memo(eTable);
const EmployerGrid = memo(eGrid);

const EmployerList = ({
	searchTerm = '',
	onCompletedQuery,
	onErrorQuery,
	onTextChange,
	tableProps,
	disableInfiniteScroll = false,
	hideSearchBar = false,
	restrictMobileResults = false,
	sx,
}: EmployerListProps) => {
	const redirect = useRedirect();
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));
	const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const isMd = useMediaQuery(theme.breakpoints.between('md', 'gridBreak'), {
		noSsr: true,
	});
	const breakpoint = isXs ? 'xs' : isSm ? 'sm' : isMd ? 'md' : 'desktop';
	const isDesktopOrLaptop = useMediaQuery(theme.breakpoints.up('gridBreak'), {
		noSsr: true,
	});

	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(
		DEFAULT_PAGE_SIZE(restrictMobileResults)[breakpoint],
	);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [orderBy, setOrderBy] = useState<{ [k: string]: any }[]>([]);
	const [sortModel, setSortModel] = useState<GridSortModel>([]);
	const [search, setSearch] = useState(searchTerm);
	const debouncedSearch = useDebounce(search, 1000);

	const [initialized, setInitialized] = useState(false);

	// Reset the page whenever the underlying query changes
	useEffect(() => {
		setPage(0);
	}, [orderBy, setPage]);

	// Reset page and orderBy upon search
	useEffect(() => {
		setPage(0);
		if (searchTerm) {
			// Default the search to sort by relevance.  The user can change the order afterwards.
			setOrderBy([
				{
					relevance: 'desc',
				},
			]);
			// Relevance does not appear in the DataGrid sort model
			setSortModel([]);
		} else {
			setOrderBy([]);
			setSortModel([]);
		}
	}, [searchTerm, setPage, setOrderBy]);

	const handleTextChange = useCallback(
		(ev) => {
			setSearch(ev.target.value);
		},
		[setSearch],
	);

	// Don't render anything while switching from mobile to desktop until state fully reset
	// Can reproduce DataGrid crash by going to mobile, scrolling down until a couple pages have loaded, and then
	// switching to desktop. DataGrid will try to scroll to an index and fail.
	const [resetting, setResetting] = useState(false);

	useEffect(() => {
		setResetting(true);
	}, [isDesktopOrLaptop]);

	useEffect(() => {
		if (!resetting) {
			return;
		}
		setPage(0);
		setPageSize(DEFAULT_PAGE_SIZE(restrictMobileResults)[breakpoint]);

		setOrderBy(
			searchTerm
				? [
						{
							relevance: 'desc',
						},
				  ]
				: [],
		);
		setSortModel([]);

		setResetting(false);
	}, [
		resetting,
		breakpoint,
		searchTerm,
		setPage,
		setPageSize,
		setOrderBy,
		setSortModel,
		setResetting,
		restrictMobileResults,
	]);

	// Get the connections for that employer
	const { data, loading, error, fetchMore, networkStatus } = useQuery(
		EmployerList_SearchEmployersQDoc,
		{
			variables: {
				search: searchTerm,
				orderBy: [
					...orderBy,
					// Use the default ordering as a tiebreaker
					...DEFAULT_ORDER_BY,
				],
				limit: isDesktopOrLaptop ? pageSize : (page + 1) * pageSize,
				offset: isDesktopOrLaptop ? page * pageSize : 0,
			},
			notifyOnNetworkStatusChange: true,
		},
	);
	useNotifyError(error);

	useEffect(() => {
		const onCompleted = (_data) => {
			if (onCompletedQuery) onCompletedQuery(_data);
		};
		const onError = (_error) => {
			if (onErrorQuery) onErrorQuery(_error);
		};
		if (onCompleted || onError) {
			if (onCompleted && !loading && !error) {
				onCompleted(data);
			} else if (onError && !loading && error) {
				onError(error);
			}
		}
	}, [loading, data, error, onCompletedQuery, onErrorQuery]);

	const markets = useMemo(() => {
		return (
			data?.search
				.map((el) => el.market)
				.filter((el): el is NonNullable<typeof el> => !!el) ?? []
		);
	}, [data]);

	const handlePageChange = useCallback(
		async (newPage) => {
			await fetchMore({
				variables: {
					offset: newPage * pageSize,
				},
			});
			setPage(newPage);
		},
		[fetchMore, pageSize, setPage],
	);

	const handlePageSizeChange = useCallback(
		async (newPageSize) => {
			await fetchMore({
				variables: {
					limit: newPageSize,
					offset: page * newPageSize,
				},
			});
			setPageSize(newPageSize);
		},
		[fetchMore, page, setPageSize],
	);

	const handleFetchPage = useCallback(async () => {
		await fetchMore({
			variables: {
				limit: pageSize,
				offset: (page + 1) * pageSize,
			},
		});
		setPage(page + 1);
	}, [fetchMore, page, pageSize, setPage]);

	const handleSortChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(newSortModel: GridSortModel, newOrderBy: { [k: string]: any }[]) => {
			setSortModel(newSortModel);
			setOrderBy(newOrderBy);
		},
		[setSortModel, setOrderBy],
	);

	const handleEmployerCardClicked = useCallback(
		(employerId: string) => {
			const org = markets.find((el) => el.id === employerId)?.org;
			if (!org) return;

			redirect(
				buildInternalLink(RouteLink.routes.ORG_SHOW, {
					orgId: [org.id, org.slug],
				}),
				{
					state: {
						backNav: {
							target: 'EM Market',
						},
					},
				},
			);
		},
		[markets, redirect],
	);

	useEffect(() => setInitialized(true), []);

	useEffect(() => {
		if (!initialized) return;
		onTextChange?.(debouncedSearch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearch, onTextChange]);

	return (
		<Box sx={sx}>
			{resetting ? null : isDesktopOrLaptop ? (
				<>
					<Box id='employer-control'>
						<Box className='container' sx={{ height: '111px' }}>
							{hideSearchBar ? null : (
								<TextField
									id='input-employer-search'
									placeholder='Search by employer name'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Search />
											</InputAdornment>
										),
										sx: {
											pl: 1,
											borderRadius: '999px !important',
											bgcolor: 'white',
										},
									}}
									value={search}
									onChange={handleTextChange}
									variant='outlined'
									sx={{
										width: '600px',
										maxWidth: '100%',
										transition: 'top 0.4s',
									}}
								/>
							)}
						</Box>
					</Box>
					<Suspense
						fallback={<TableSkeleton sx={tableProps?.sx} size={pageSize} />}
					>
						<EmployerTable
							page={page}
							onChangeSort={handleSortChange}
							sortModel={sortModel}
							employers={markets}
							loading={loading}
							pageSize={pageSize}
							onPageSizeChange={handlePageSizeChange}
							onPageChange={handlePageChange}
							rowCount={data?.agg.aggregate?.count || 0}
							filtering={!!searchTerm}
							columnVisibilityModel={INITIAL_COLUMNS}
							{...tableProps}
						/>
					</Suspense>
				</>
			) : (
				<Suspense fallback={<GridSkeleton size={pageSize} />}>
					<EmployerGrid
						employers={markets}
						networkStatus={networkStatus}
						loading={loading}
						onClickCard={handleEmployerCardClicked}
						onFetchPage={handleFetchPage}
						searchTerm={searchTerm}
						pageSize={pageSize}
						count={data?.agg.aggregate?.count || 0}
						atMaxPage={
							disableInfiniteScroll ||
							(!!data && data.agg.aggregate!.count === data.search.length)
						}
					/>
				</Suspense>
			)}
		</Box>
	);
};

export default EmployerList;
