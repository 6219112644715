import React from 'react';

import { Grid, Skeleton } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

import { type SkeletonProps } from './GridSkeleton';

const TableSkeleton = ({ size = 0, sx }: SkeletonProps) => (
	<Grid
		sx={combineSx({ overflow: 'hidden' }, sx)}
		container
		wrap='nowrap'
		direction='column'
		spacing={2}
	>
		<Grid item xs={12} pb={2}>
			<Skeleton variant='rectangular' height={93} />
		</Grid>
		{[...Array(size).keys()].map((el) => (
			<Grid key={el} container item xs={12} spacing={2}>
				<Grid item xs={1} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
				<Grid item xs={4} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
				<Grid item xs={2} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
				<Grid item xs={3} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
				<Grid item xs={2} py={3}>
					<Skeleton variant='rectangular' height={30} />
				</Grid>
			</Grid>
		))}
	</Grid>
);

export default TableSkeleton;
