import React from 'react';

import { Grid, Skeleton, type SxProps, type Theme } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

export interface SkeletonProps {
	size?: number;
	sx?: SxProps<Theme>;
}

const GridSkeleton = ({ sx, size = 0 }: SkeletonProps) => (
	<Grid container spacing={3} sx={combineSx({ justifyContent: 'center' }, sx)}>
		{[...Array(size).keys()].map((el) => (
			<Grid item key={el} xs={12} sm={6} md={4}>
				<Skeleton variant='rectangular' width='100%' height={130} />
			</Grid>
		))}
	</Grid>
);

export default GridSkeleton;
