import React from 'react';

import { renameLabel, SubStep } from '@ivy/components/organisms/FormPopup';
import {
	formatForm,
	type FormStepProps,
	type SubStepObj,
} from '@ivy/lib/forms/formFormatHelpers';

import {
	type ProfileCompleteFormValues,
	type ExtraParams,
} from '../profileCompleteForm';

import APPCerts, {
	type APPCertsValues,
	validation as certsValidation,
	initialValue as certsInitialValue,
} from './APPCerts';
import BoardCerts, {
	type BoardCertsValues,
	validation as boardValidation,
	initialValue as boardInitialValue,
} from './BoardCerts';
import ClinicianSearch, {
	initialValue as clnSearchInitialValue,
	validation as clnSearchValidation,
	type ClinicianSearchValues,
} from './ClinicianSearch';
import Credentials, {
	type CredentialsValues,
	validation as credValidation,
	initialValue as credInitialValue,
} from './Credentials';
import Experience, {
	type ExperienceValues,
	validation as expValidation,
	initialValue as expInitialValue,
} from './Experience';
import License, {
	type LicenseValues,
	validation as licenseValidation,
	initialValue as licenseInitialValue,
} from './License';
import Residency, {
	type TrainingObj,
	type ResidencyValues,
	appValidation,
	fellowValidation,
	resValidation,
	initialValue as resInitialValue,
} from './Residency';
import School, {
	type SchoolValues,
	validation as shlValidation,
	initialValue as shlInitialValue,
} from './School';
import Specialty, {
	type SpecialtyValues,
	validation as specValidation,
	initialValue as specInitialValue,
} from './Specialty';
import UploadFile, {
	type UploadFileValues,
	cvValidation,
	pictureValidation,
	initialValue as uploadInitialValue,
} from './UploadFile';

type ProfileStepValues = UploadFileValues &
	ResidencyValues &
	LicenseValues &
	SpecialtyValues &
	APPCertsValues &
	BoardCertsValues &
	SchoolValues &
	CredentialsValues &
	ExperienceValues &
	ClinicianSearchValues;

const steps: SubStepObj<ProfileCompleteFormValues, ExtraParams>[] = [
	{
		key: 'search',
		// shouldShow: (values) => values.clinician.profDegree !== ProfDegree.NP,
		shouldShow: () => false,
		label: () => 'Search for yourself from the list below',
		component: ClinicianSearch,
		validate: clnSearchValidation,
		initialValue: clnSearchInitialValue,
	},
	{
		key: 'school',
		label: (values, extra) =>
			renameLabel(
				extra.clinician.isAPP
					? `${extra.clinician.profDegree} program`
					: 'Medical school & graduation year',
				(values.schoolName &&
					((values.currStudent && !values.gradYr) || values.gradYr) &&
					`${values.schoolName}${
						values.currStudent ? '' : `, ${values.gradYr?.getFullYear()}`
					}`) ||
					'',
				true,
			),
		component: School,
		validate: shlValidation,
		initialValue: shlInitialValue,
	},
	{
		key: 'licenses',
		shouldShow: (values) => !values.clinician.fsmbBio,
		label: (values, extra) =>
			renameLabel(
				extra.clinician.isAPP
					? `${extra.clinician.profDegree} licenses`
					: 'Medical licenses',
				values.licenses.map((el) => el.state).join(', '),
				false,
			),
		component: License,
		validate: licenseValidation,
		namespace: 'licenses',
		initialValue: licenseInitialValue,
	},
	{
		key: 'specialty',
		shouldShow: (values) => !!values && !values.clinician.isAPP,
		label: (values) =>
			renameLabel(
				'Primary specialty',
				values.specialties.map((el) => el.name).join(', '),
				true,
			),
		component: Specialty,
		validate: specValidation,
		initialValue: specInitialValue,
		namespace: 'specialties',
	},
	{
		key: 'credentials',
		label: (values) => renameLabel('Credentials', values.creds, true),
		component: Credentials,
		validate: credValidation,
		initialValue: credInitialValue,
		namespace: 'creds',
	},
	{
		key: 'residency',
		shouldShow: (values) => !values.clinician.isAPP,
		label: (values) =>
			renameLabel(
				'Residency',
				values.residency
					.map(
						(residency) =>
							(residency.program &&
								residency.startYear &&
								((residency.present && !residency.endYear) ||
									residency.endYear) &&
								`${residency.program}, ${residency.startYear.getFullYear()} - ${
									residency.present
										? 'present'
										: residency.endYear?.getFullYear() || ''
								}`) ||
							'',
					)
					.join(', '),
				true,
			),
		component: Residency,
		validate: resValidation,
		initialValue: resInitialValue,
		namespace: 'residency',
	},
	{
		key: 'fellowship',
		shouldShow: (values) => !values.clinician.isAPP,
		label: (values) =>
			renameLabel(
				'Fellowship',
				values.fellowship
					.map(
						(fellowship) =>
							(fellowship.program &&
								fellowship.startYear &&
								((fellowship.present && !fellowship.endYear) ||
									fellowship.endYear) &&
								`${
									fellowship.program
								}, ${fellowship.startYear.getFullYear()} - ${
									fellowship.present
										? 'present'
										: fellowship.endYear?.getFullYear() || ''
								}`) ||
							'',
					)
					.join(', '),
				false,
			),
		component: Residency,
		validate: fellowValidation,
		namespace: 'fellowship',
	},
	{
		key: 'training',
		shouldShow: (values) => values.clinician.isAPP,
		label: (values) =>
			renameLabel(
				'Postgrad specialty training',
				values.appTraining
					.map(
						(appTraining) =>
							(appTraining.program &&
								appTraining.startYear &&
								((appTraining.present && !appTraining.endYear) ||
									appTraining.endYear) &&
								`${
									appTraining.program
								}, ${appTraining.startYear.getFullYear()} - ${
									appTraining.present
										? 'present'
										: appTraining.endYear?.getFullYear() || ''
								}`) ||
							'',
					)
					.join(', '),
				false,
			),
		component: Residency,
		validate: appValidation,
		namespace: 'appTraining',
	},
	{
		key: 'appCerts',
		shouldShow: (values) => values.clinician.isAPP,
		label: (values) =>
			renameLabel(
				'Certification & courses',
				values.appCerts.map((el) => el.name).join(', '),
				false,
			),
		component: APPCerts,
		validate: certsValidation,
		initialValue: certsInitialValue,
		namespace: 'appCerts',
	},
	{
		key: 'spExp',
		shouldShow: (values) => values.clinician.isAPP,
		label: (values) =>
			renameLabel(
				'Years of postgrad experience',
				values.spExp.map((el) => el.field).join(', '),
				false,
			),
		component: Experience,
		validate: expValidation,
		initialValue: expInitialValue,
		namespace: 'spExp',
	},
	{
		key: 'board',
		shouldShow: (values) => !values.clinician.isAPP,
		label: (values) =>
			renameLabel(
				'Board certifications',
				values.boardCerts
					.map((el) => el.cert?.field)
					.filter((el) => !!el)
					.join(', '),
				false,
			),
		component: BoardCerts,
		validate: boardValidation,
		initialValue: boardInitialValue,
		namespace: 'boardCerts',
	},
	{
		key: 'cv',
		label: (values) => renameLabel('Upload CV', !!values.cv?.id, false),
		component: UploadFile,
		validate: cvValidation,
		initialValue: uploadInitialValue,
	},
	{
		key: 'picture',
		label: (values) =>
			renameLabel('Upload profile picture', !!values.picture?.id, false),
		component: UploadFile,
		validate: pictureValidation,
	},
];

const { formSteps, initialValues, validation } = formatForm(steps);
const ProfileStep = (
	props: FormStepProps<ProfileCompleteFormValues, ExtraParams>,
) => <SubStep {...props} subSteps={formSteps} />;
export default ProfileStep;
export { type ProfileStepValues, type TrainingObj, validation, initialValues };
