import * as yup from 'yup';

import { WorkerClassification, Profession } from '@ivy/constants/clinician';
import { stateAbbreviations } from '@ivy/constants/location';
import { OwnershipStructure } from '@ivy/constants/organization';
import { ReviewStatus } from '@ivy/constants/review';
import { createFragment } from '@ivy/gql/fragment';
import { PHONE_REGEX } from '@ivy/lib/validation/phone';

export const adminRecruiterFragment = createFragment(
	'AdminRecruiterFragment',
	'account',
	`
    id
    active
    email
    firstName: first_name
    lastName: last_name
    confirmedEmail: confirmed_email
    orgUser: org_user {
        id
		disableAdminFollowup: disable_admin_followup
		reporting
        org {
            id
            name
        }
    }
`,
);

export const RECRUITER_SCHEMA = yup.object({
	firstName: yup.string().required(),
	lastName: yup.string().required(),
	email: yup.string().email().required(),
	org: yup
		.object({
			id: yup.string().required(),
		})
		.required(),
});

export const adminOrgFragment = createFragment(
	'AdminOrgFragment',
	'organization',
	`
    id
    name
    website
    verified
    active
    ownershipStructure: ownership_structure
    domains {
        id
        name
    }
    logo {
    	id
    	publicUrl: public_url
    	filename
    }
    description
    phone
    streetAddress1: street_address_1
    streetAddress2: street_address_2
    city
    state
    zipcode
    defaultRecruiter: default_recruiter {
		id
		orgId: org_id
		account {
			id
			email
			pi: personal_info {
				id
				firstName: first_name
				lastName: last_name
				fullName: full_name
			}
		}
    }
`,
);

export const ORG_SCHEMA = yup.object({
	name: yup.string().required(),
	website: yup.string().when((value, schema) => {
		if (!value) {
			return schema;
		}
		return schema
			.transform((currVal) => {
				if (value.includes('http://') || value.includes('https://')) {
					return currVal;
				}
				return `https://${currVal}`;
			})
			.url();
	}),
	ownershipStructure: yup
		.string()
		.nullable()
		.oneOf([...Object.values(OwnershipStructure), null]),
	domains: yup
		.array()
		.of(
			yup
				.string()
				.matches(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i, 'Invalid domain')
				.required(),
		)
		.required(),
	// Unable to allow undefined values when using .object({ id: yup.string().required() }), so using .when instead
	defaultRecruiter: yup
		.object()
		.nullable()
		.optional()
		.when((value, schema) =>
			value ? schema.shape({ id: yup.string().required() }) : schema,
		),
	phone: yup
		.string()
		.when((value, schema) => {
			if (value) {
				return schema.matches(
					PHONE_REGEX,
					'Must be of the format (XXX) XXX-XXXX',
				);
			}
			return schema;
		})
		.label('Phone number'),
	logo: yup.object().nullable(),
	description: yup.string(),
	streetAddress1: yup.string(),
	streetAddress2: yup.string().when('streetAddress1', {
		is: (value) => value,
		then: (schema) => schema,
		otherwise: (schema) => schema.oneOf([''], 'Must be empty'),
	}),
	city: yup.string().when('streetAddress1', {
		is: (value) => value,
		then: (schema) => schema.required(),
		otherwise: (schema) => schema.oneOf([''], 'Must be empty'),
	}),
	state: yup.string().when('streetAddress1', {
		is: (value) => value,
		then: (schema) => schema.oneOf(stateAbbreviations).required(),
		otherwise: (schema) => schema.oneOf([''], 'Must be empty'),
	}),
	zipcode: yup.string().when('streetAddress1', {
		is: (value) => value,
		then: (schema) => schema.required(),
		otherwise: (schema) => schema.oneOf([''], 'Must be empty'),
	}),
});

export const adminFacilityFragment = createFragment(
	'AdminFacilityPopupFacilityFragment',
	'facility',
	`
    id
    name
    slug
    adultTraumaLvl: adult_trauma_lvl
    freestanding: freestanding_er
	residencies {
		id
		training {
			id
			name
		}
	}
    cmsFacilityId: cms_facility_id
    ahaFacilityId: aha_facility_22_id
    streetAddress1: street_address_1
    streetAddress2: street_address_2
    city
    state
    zipcode
    picture {
        id
        filename
        publicUrl: public_url
    }
    edis: ehrs(where: { type: { _eq: EDIS } }, order_by: { name: asc }) {
        id
        name
        type
    }
    contracts(where: { active: { _eq: true } }, order_by: { created_at: asc }) {
        id
        org {
            id
            name
            ownershipStructure: ownership_structure
        }
    }
	cmsFacilityPos: cms_facility_pos {
		id
		providerSubtype: provider_subtype
	}
	cmsFacilityEnrollment: cms_facility_enrollment {
		id
		providerTypeCode: provider_type_code
	}
	cmsFacility: cms_facility {
		id
		hospitalType: hospital_type
	}
	ahaFacility: aha_facility_22 {
		id: ID
		healthSystem: SYSNAME
	}
`,
);

export const adminContractFragment = createFragment(
	'AdminFacilityContractFragment',
	'facility_contract',
	`
    id
    active
    changeRequest: change_request
    featured {
        id
        profession
    }
    recruiter {
        id
        orgId: org_id
        account {
            id
            email
            pi: personal_info {
                id
                firstName: first_name
                lastName: last_name
                fullName: full_name
            }
            confirmedEmail: confirmed_email
        }
    }
    org {
        id
        name
        ownershipStructure: ownership_structure
		defaultRecruiter: default_recruiter {
			id
		}
    }
    facility {
        id
        name
        slug
        city
        state
        freestandingEr: freestanding_er
    }
`,
);

export const FACILITY_SCHEMA = yup.object({
	name: yup.string().required(),
	picture: yup
		.object({
			id: yup.string().required(),
		})
		.nullable(),
	adultTraumaLvl: yup.number().integer().nullable().min(1).max(5),
	freestanding: yup.boolean().required(),
	residency: yup.string(),
	cmsFacilityId: yup.string(),
	streetAddress1: yup.string().required(),
	streetAddress2: yup.string(),
	city: yup.string().required(),
	state: yup.string().oneOf(stateAbbreviations).required(),
	zipcode: yup.string().required(),
});

export const CONTRACT_SCHEMA = yup.object({
	facility: yup
		.object({
			id: yup.string().required(),
		})
		.required(),
	org: yup
		.object({
			id: yup.string().required(),
		})
		.required(),
	recruiter: yup
		.object({
			id: yup.string().required(),
			orgId: yup.string().required(),
		})
		.required(),
});

export const adminContext = {
	headers: {
		'x-hasura-role': 'admin',
	},
};

export const SALARY_REPORT_SCHEMA = yup.object({
	worker: yup.string().oneOf(Object.values(WorkerClassification)).required(),
	partnershipTrack: yup.boolean().required(),
	traveler: yup.boolean().required(),
	admin: yup.boolean().required(),
	nightDiff: yup.boolean().required(),
	weekendDiff: yup.boolean().required(),
	bonus: yup.boolean().required(),
	studentLoan: yup.boolean().required(),
	cme: yup.boolean().required(),
	retirement: yup.boolean().required(),
	experience: yup
		.number()
		.integer('Experience: Must be a whole number')
		.min(0, 'Experience: Must be at least 0')
		.max(9999, 'Experience: Must be less than 10000')
		.typeError('Experience: Please enter a whole number')
		.nullable(),
	profession: yup
		.string()
		.oneOf(Object.values(Profession))
		.typeError('Profession: Please select a profession')
		.required(),
	baseAmt: yup
		.number()
		.integer('Base: Must be a whole number')
		.min(1, 'Base: Must be at least $1')
		.max(10000000, 'Base: Must be less than $10,000,000')
		.typeError('Base: Please enter a whole number')
		.required(),
	nightDiffAmt: yup
		.number()
		.integer('Night Diff: Must be a whole number')
		.min(1, 'Night Diff: Must be at least $1')
		.max(10000000, 'Night Diff: Must be less than $10,000,000')
		.typeError('Night Diff: Please enter a whole number')
		.nullable(),
	weekendDiffAmt: yup
		.number()
		.integer('Weekend Diff: Must be a whole number')
		.min(1, 'Weekend Diff: Must be at least $1')
		.max(10000000, 'Weekend Diff: Must be less than $10,000,000')
		.typeError('Weekend Diff: Please enter a whole number')
		.nullable(),
	studentLoanAmt: yup
		.number()
		.integer('Student Loan: Must be a whole number')
		.min(1, 'Student Loan: Must be at least $1')
		.max(10000000, 'Student Loan: Must be less than $10,000,000')
		.typeError('Student Loan: Please enter a whole number')
		.nullable(),
	cmeAmt: yup
		.number()
		.integer('CME: Must be a whole number')
		.min(1, 'CME: Must be at least $1')
		.max(10000000, 'CME: Must be less than $10,000,000')
		.typeError('CME: Please enter a whole number')
		.nullable(),
	shftsMonth: yup
		.number()
		.integer('Shifts Per Month: Must be a whole number')
		.min(1, 'Shifts Per Month: Must be at least 1')
		.max(31, 'Shifts Per Month: Must be less than 32')
		.typeError('Shifts Per Month: Please enter a whole number')
		.nullable(),
	hrsShift: yup
		.number()
		.integer('Hours Per Shift: Must be a whole number')
		.min(1, 'Hours Per Shift: Must be at least 1')
		.max(24, 'Hours Per Shift: Must be less than 25')
		.typeError('Hours Per Shift: Please enter a whole number')
		.nullable(),
	bonusAmt: yup.string().nullable(),
	retirementAmt: yup.string().nullable(),
});

export const REVIEW_SCHEMA = yup.object({
	status: yup.string().oneOf(Object.values(ReviewStatus)).required(),
	moderationStatus: yup.string().oneOf(Object.values(ReviewStatus)).required(),
});
