import React, {
	useState,
	useCallback,
	useMemo,
	useEffect,
	memo,
	Suspense,
} from 'react';

import { useQuery } from '@apollo/client';
import { Search } from '@mui/icons-material';
import { Add } from '@mui/icons-material';
import {
	Box,
	type SxProps,
	type Theme,
	TextField,
	InputAdornment,
	Stack,
	useMediaQuery,
	useTheme,
	Button,
} from '@mui/material';
import { type GridSortModel } from '@mui/x-data-grid-premium';
import find from 'lodash/find';

import {
	GridSkeleton,
	TableSkeleton,
} from '@ivy/components/molecules/ListSkeletons';
import Popup from '@ivy/components/molecules/Popup';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import { type SalaryList_SearchSalaryReportsQueryVariables } from '@ivy/gql/types/graphql';
import { useDebounce } from '@ivy/lib/hooks';
import { useNotifyError } from '@ivy/lib/hooks';
import lazyRetry from '@ivy/lib/util/lazyRetry';

import {
	FiltersProvider,
	useFilterContext,
	type FiltersProviderProps,
	FilterBar,
	FiltersPopup,
} from './filters';
import ReportCallToAction from './ReportCallToAction';
import ReportDetail from './ReportDetail';
import { type SalaryTableProps } from './SalaryTable';

// Since we have multiple lazyRetry imports, we need to pass a unique key to each
const sGrid = lazyRetry(() => import('./SalaryGrid'), 'SalaryList_SalaryGrid');
const sTable = lazyRetry(
	() => import('./SalaryTable'),
	'SalaryList_SalaryTable',
);

const DEFAULT_PAGE_SIZE = (blur, restrict) => ({
	// 10 rows of 1 card
	xs: blur ? 4 : restrict ? 3 : 10,
	// 10 rows of 2 cards
	sm: (blur ? 4 : restrict ? 2 : 10) * 2,
	// 10 rows of 3 cards
	md: (blur ? 4 : restrict ? 2 : 10) * 3,
	desktop: 25,
});

// We're including inactive contracts in filters + connections
export const SalaryList_SearchSalaryReportsQDoc = gql(/* GraphQL */ `
	query SalaryList_SearchSalaryReports(
		$limit: Int!
		$offset: Int!
		$orderBy: [relevant_salary_report_order_by!]
		$search: String!
		$reportFilters: relevant_salary_report_bool_exp!
	) {
		relevantSalaryReports: search_relevant_salary_report(
			where: $reportFilters
			limit: $limit
			offset: $offset
			order_by: $orderBy
			args: { search: $search }
		) {
			id
			salaryReport: salary_report_fmt {
				id
				...SalaryCard_SalaryReportInfo
				...SalaryTable_SalaryReportInfo
				...ReportDetail_SalaryReportInfo
			}
		}
		agg: search_relevant_salary_report_aggregate(
			where: $reportFilters
			args: { search: $search }
		) {
			aggregate {
				count
			}
		}
	}
`);

const DEFAULT_ORDER_BY = [
	{
		salary_report_fmt: {
			created_at: 'desc',
		},
	},
	{
		salary_report_fmt: {
			id: 'asc',
		},
	},
];

interface SalaryListProps {
	searchTerm?: string;
	filtersPopupOpen?: boolean;
	onCompletedQuery?: (val) => void;
	onErrorQuery?: (val) => void;
	onClickAddSalary: (event: React.SyntheticEvent) => void;
	setFiltersPopupOpen?: (newValue: boolean) => void;
	onTextChange?: (newValue: string) => void;
	where?: SalaryList_SearchSalaryReportsQueryVariables['reportFilters'];
	tableProps?: Omit<
		SalaryTableProps,
		| 'onChangeSort'
		| 'reports'
		| 'loading'
		| 'onClickAddSalary'
		| 'filtering'
		| 'blur'
	>;
	disableInfiniteScroll?: boolean;
	hideAddSalaryButton?: boolean;
	hideSearchBar?: boolean;
	restrictMobileResults?: boolean;
	sx?: SxProps<Theme>;
}

const SalaryTable = memo(sTable);
const SalaryGrid = memo(sGrid);

const SalaryList = ({
	searchTerm = '',
	onCompletedQuery,
	onErrorQuery,
	onClickAddSalary,
	onTextChange,
	setFiltersPopupOpen,
	where,
	tableProps,
	filtersPopupOpen = false,
	disableInfiniteScroll = false,
	hideAddSalaryButton = false,
	hideSearchBar = false,
	restrictMobileResults = false,
	sx,
}: SalaryListProps) => {
	const currAcc = useCurrentAccount();
	const { apiFilters, filtersCount, appliedColumns } = useFilterContext();

	const shouldBlur =
		!currAcc ||
		(!currAcc.clinician?.reportedSalary &&
			!currAcc.superuser &&
			!currAcc.accessSalary);

	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));
	const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const isMd = useMediaQuery(theme.breakpoints.between('md', 'gridBreak'), {
		noSsr: true,
	});
	const breakpoint = isXs ? 'xs' : isSm ? 'sm' : isMd ? 'md' : 'desktop';
	const isDesktopOrLaptop = useMediaQuery(theme.breakpoints.up('gridBreak'), {
		noSsr: true,
	});

	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(
		DEFAULT_PAGE_SIZE(shouldBlur, restrictMobileResults)[breakpoint],
	);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [focusedReport, setFocusedReport] = useState<any>();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [orderBy, setOrderBy] = useState<{ [k: string]: any }[]>([]);
	const [sortModel, setSortModel] = useState<GridSortModel>([]);
	const [search, setSearch] = useState(searchTerm);
	const debouncedSearch = useDebounce(search, 1000);

	const [initialized, setInitialized] = useState(false);

	// Reset the page whenever the underlying query changes
	useEffect(() => {
		setPage(0);
	}, [apiFilters, orderBy, setPage]);

	// Reset page and orderBy upon search
	useEffect(() => {
		setPage(0);
		if (searchTerm) {
			// Default the search to sort by relevance.  The user can change the order afterwards.
			setOrderBy([
				{
					relevance: 'desc',
				},
			]);
			// Relevance does not appear in the DataGrid sort model
			setSortModel([]);
		} else {
			setOrderBy([]);
			setSortModel([]);
		}
	}, [searchTerm, setPage, setOrderBy]);

	const handleTextChange = useCallback(
		(ev) => {
			setSearch(ev.target.value);
		},
		[setSearch],
	);

	// Don't render anything while switching from mobile to desktop until state fully reset
	// Can reproduce DataGrid crash by going to mobile, scrolling down until a couple pages have loaded, and then
	// switching to desktop. DataGrid will try to scroll to an index and fail.
	const [resetting, setResetting] = useState(false);

	useEffect(() => {
		setResetting(true);
	}, [isDesktopOrLaptop]);

	useEffect(() => {
		if (!resetting) {
			return;
		}
		setPage(0);
		setPageSize(
			DEFAULT_PAGE_SIZE(shouldBlur, restrictMobileResults)[breakpoint],
		);

		setOrderBy(
			searchTerm
				? [
						{
							relevance: 'desc',
						},
				  ]
				: [],
		);
		setSortModel([]);

		setResetting(false);
	}, [
		resetting,
		breakpoint,
		searchTerm,
		shouldBlur,
		setPage,
		setPageSize,
		setOrderBy,
		setSortModel,
		setResetting,
		restrictMobileResults,
	]);

	// Get the connections for that employer
	const { data, loading, error, fetchMore, networkStatus } = useQuery(
		SalaryList_SearchSalaryReportsQDoc,
		{
			variables: {
				search: searchTerm,
				reportFilters: {
					_and: [...apiFilters, where || {}],
				},
				orderBy: [
					...orderBy,
					// Use the default ordering as a tiebreaker
					...DEFAULT_ORDER_BY,
				],
				limit: isDesktopOrLaptop ? pageSize : (page + 1) * pageSize,
				offset: isDesktopOrLaptop ? page * pageSize : 0,
			},
			notifyOnNetworkStatusChange: true,
		},
	);
	useNotifyError(error);

	useEffect(() => {
		const onCompleted = (_data) => {
			if (onCompletedQuery) onCompletedQuery(_data);
		};
		const onError = (_error) => {
			if (onErrorQuery) onErrorQuery(_error);
		};
		if (onCompleted || onError) {
			if (onCompleted && !loading && !error) {
				onCompleted(data);
			} else if (onError && !loading && error) {
				onError(error);
			}
		}
	}, [loading, data, error, onCompletedQuery, onErrorQuery]);

	const reports = useMemo(() => {
		return data?.relevantSalaryReports.map((el) => el.salaryReport!) || [];
	}, [data]);

	const handlePageChange = useCallback(
		async (newPage) => {
			await fetchMore({
				variables: {
					offset: newPage * pageSize,
				},
			});
			setPage(newPage);
		},
		[fetchMore, pageSize, setPage],
	);

	const handlePageSizeChange = useCallback(
		async (newPageSize) => {
			await fetchMore({
				variables: {
					limit: newPageSize,
					offset: page * newPageSize,
				},
			});
			setPageSize(newPageSize);
		},
		[fetchMore, page, setPageSize],
	);

	const handleFetchPage = useCallback(async () => {
		if (!shouldBlur) {
			await fetchMore({
				variables: {
					limit: pageSize,
					offset: (page + 1) * pageSize,
				},
			});

			setPage(page + 1);
		}
	}, [fetchMore, page, pageSize, setPage, shouldBlur]);

	const handleSortChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(newSortModel: GridSortModel, newOrderBy: { [k: string]: any }[]) => {
			setSortModel(newSortModel);
			setOrderBy(newOrderBy);
		},
		[setSortModel, setOrderBy],
	);

	const handleSalaryReportCardClicked = useCallback(
		(reportId: string) => {
			const foundReport = find(reports, { id: reportId });
			setFocusedReport(foundReport);
		},
		[setFocusedReport, reports],
	);

	const handleCloseReportDetail = useCallback(() => {
		setFocusedReport(null);
	}, [setFocusedReport]);

	const filtersActive = Object.values(filtersCount).some((el) => !!el);

	useEffect(() => setInitialized(true), []);

	useEffect(() => {
		if (!initialized) return;
		onTextChange?.(debouncedSearch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearch, onTextChange]);

	return data?.agg.aggregate?.count === 0 &&
		!filtersActive &&
		searchTerm === '' ? (
		<ReportCallToAction
			id='salary-report-call-to-action'
			primary='Be the first to submit'
			onClick={onClickAddSalary}
			sx={{ width: '100%', maxWidth: '550px', margin: 'auto' }}
		/>
	) : (
		<Box sx={sx}>
			{resetting ? null : isDesktopOrLaptop ? (
				<>
					<Box id='salary-control'>
						<Box className='container' sx={{ height: '111px' }}>
							{hideSearchBar ? null : (
								<TextField
									id='input-salary-search'
									placeholder='Search by location or employer'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Search />
											</InputAdornment>
										),
										sx: {
											pl: 1,
											borderRadius: '999px !important',
											bgcolor: 'white',
										},
									}}
									value={search}
									onChange={handleTextChange}
									variant='outlined'
									sx={{
										width: '600px',
										maxWidth: '100%',
										transition: 'top 0.4s',
									}}
								/>
							)}
							<Stack
								id='salary-filter-container'
								direction='row'
								justifyContent='space-between'
							>
								<FilterBar id='salary--filter' />
								<Button
									id='salary-add--btn'
									variant='contained'
									sx={{
										width: '160px',
										height: '55px',
										fontSize: '16px',
										display:
											(currAcc && !currAcc.isClinician) || hideAddSalaryButton
												? 'none'
												: 'flex',
									}}
									onClick={onClickAddSalary}
								>
									<Add fontSize='medium' sx={{ mr: 1 }} />
									Add salary
								</Button>
							</Stack>
						</Box>
					</Box>
					<Suspense
						fallback={<TableSkeleton sx={tableProps?.sx} size={pageSize} />}
					>
						<SalaryTable
							page={page}
							onChangeSort={handleSortChange}
							sortModel={sortModel}
							reports={reports}
							loading={loading}
							pageSize={pageSize}
							onPageSizeChange={handlePageSizeChange}
							onPageChange={handlePageChange}
							rowCount={data?.agg.aggregate?.count || 0}
							filtering={filtersActive || !!searchTerm}
							columnVisibilityModel={appliedColumns}
							onClickAddSalary={onClickAddSalary}
							blur={shouldBlur}
							getRowClassName={(params) =>
								params.indexRelativeToCurrentPage !== 0 && shouldBlur
									? `blur-data`
									: ''
							}
							{...tableProps}
						/>
					</Suspense>
				</>
			) : (
				<>
					{setFiltersPopupOpen ? (
						<FiltersPopup
							open={filtersPopupOpen}
							onClose={() => setFiltersPopupOpen(false)}
						/>
					) : undefined}
					<Popup
						open={!!focusedReport}
						title='Salary Report'
						onClose={handleCloseReportDetail}
						contentSx={{ px: 2 }}
					>
						<ReportDetail report={focusedReport} />
					</Popup>
					<Suspense fallback={<GridSkeleton size={pageSize} />}>
						<SalaryGrid
							reports={reports}
							networkStatus={networkStatus}
							blur={shouldBlur}
							loading={loading}
							filtersActive={filtersActive}
							onClickCard={handleSalaryReportCardClicked}
							onFetchPage={handleFetchPage}
							onClickAddSalary={onClickAddSalary}
							searchTerm={searchTerm}
							pageSize={pageSize}
							count={data?.agg.aggregate?.count || 0}
							atMaxPage={
								disableInfiniteScroll ||
								(!!data &&
									data.agg.aggregate!.count ===
										data.relevantSalaryReports.length)
							}
						/>
					</Suspense>
				</>
			)}
		</Box>
	);
};

interface SalaryListWithProviderProps extends SalaryListProps {
	providerProps?: FiltersProviderProps;
}

export const SalaryListWithProvider = ({
	providerProps,
	...props
}: SalaryListWithProviderProps) => {
	return (
		<FiltersProvider {...providerProps}>
			<SalaryList {...props} />
		</FiltersProvider>
	);
};

export default SalaryList;
