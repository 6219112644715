import React, { useState, useCallback } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import EmployerList from '@ivy/components/organisms/EmployerList';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { type EmployerList_SearchEmployersQuery } from '@ivy/gql/types/graphql';

import Hero from './Hero';

const EMMarketShow = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchTerm, setSearchTerm] = useState(() => {
		if (searchParams.has('search')) {
			return searchParams.get('search') || '';
		}
		return '';
	});

	const theme = useTheme();
	const [data, setData] = useState<EmployerList_SearchEmployersQuery>();
	const isGridBreak = useMediaQuery(theme.breakpoints.down('gridBreak'), {
		noSsr: true,
	});

	const handleChangeSearch = useCallback(
		(term) => {
			setSearchTerm(term);
			setSearchParams(term ? { search: term } : {});
		},
		[setSearchTerm, setSearchParams],
	);

	const employerCount = data?.agg.aggregate?.count || 0;

	return (
		<NavTemplate
			fullscreen
			showFooter
			backgroundColor={isGridBreak ? theme.palette.light4.main : undefined}
			TopBarProps={{
				sx: {
					borderStyle: 'none',
				},
			}}
			containerSx={{
				px: {
					gridBreak: 0,
					xs: 2,
				},
			}}
			pageTitle='State of the EM Employer Market'
			pageDescription='Examine the current state of employers on Ivy Clinicians'
			showSupportLauncher
			pathIsCanonical
		>
			<Hero
				searchTerm={searchTerm}
				onTextChange={handleChangeSearch}
				sx={{
					mb: {
						gridBreak: 0,
						xs: 3,
					},
					zIndex: 2,
				}}
			/>
			<Box
				sx={{
					mx: 'auto',
					position: 'relative',
					mb: 10,
				}}
			>
				<EmployerList
					searchTerm={searchTerm}
					onCompletedQuery={setData}
					onTextChange={handleChangeSearch}
					sx={{
						'#employer-control': {
							backgroundColor: theme.palette.light4.main,
							position: 'sticky',
							top: '64px',
							zIndex: 2,
							'& .container': {
								px: 3,
							},
						},
					}}
					tableProps={{
						autoHeight: true,
						sx: {
							'& .MuiDataGrid-main': {
								overflow: 'unset',
								'& .MuiDataGrid-columnHeaders': {
									position: 'sticky',
									top: '120px',
									zIndex: 2,
									backgroundColor: theme.palette.light4.main,
									borderRadius: 0,
									px: 2,
									'& .MuiDataGrid-columnHeadersInner': {
										maxWidth: '1712px',
									},
								},
								'& .MuiDataGrid-virtualScroller': {
									marginTop: employerCount > 0 ? '0 !important' : undefined,
									'& .MuiDataGrid-row': {
										px: 2,
									},
								},
							},
							'& .MuiDataGrid-footerContainer': {
								px: 3,
							},
						},
					}}
				/>
			</Box>
		</NavTemplate>
	);
};

export default EMMarketShow;
