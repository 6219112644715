import React, { useCallback, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';

import DataLoader from '@ivy/components/molecules/DataLoader';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';

import FreePlan from './FreePlan';
import Hero from './Hero';
import JobPostingPlans from './JobPostingPlans';
import Testimonials from './Testimonials';

// const stateImpress = lazyRetry(
// 	() => import('./StateImpressions'),
// 	'StateImpress_StateGeoPath',
// );

const MIN_HEIGHT_DESKTOP = 720;
const MIN_HEIGHT_MOBILE = 650;

const ForEmployers_FacilityCountQDoc = gql(/* GraphQL */ `
	query ForEmployers_FacilityCount {
		fac: facility_aggregate(where: { contracts: { active: { _eq: true } } }) {
			aggregate {
				count
			}
		}
		org: organization_aggregate(
			where: { contracts: { active: { _eq: true } } }
		) {
			aggregate {
				count
			}
		}
		jobPostingPrices: get_payment_prices {
			id
			interval
			tiers {
				amount
				limit
			}
		}
	}
`);

// const StateImpressions = memo(stateImpress);

const ForEmployers = () => {
	const theme = useTheme();
	const [searchParams, setSearchParams] = useSearchParams();
	const { openSignupPopup } = useAuthPopup();
	// http://localhost:3000/employers?showSignup=true&inviteEmail=matt%2Bjo%40ivyclinicians.io&inviteOrgId=f79976f4-0000-0000-0000-000000000000
	// http://localhost:3000/employers?showSignup=true&inviteEmail=matt%2Bspamrecruiter%40ivyclinicians.io&inviteOrgId=f79976f4-0000-0000-0000-000000000002

	const currAcc = useCurrentAccount();
	const { enqueueSnackbar } = useSnackbar();
	const { data, loading, error } = useQuery(ForEmployers_FacilityCountQDoc);
	const redirect = useRedirect();

	const handleClickCta = useCallback(() => {
		if (currAcc) {
			if (currAcc.isClinician) {
				enqueueSnackbar("You're already signed up as a clinician", {
					variant: 'info',
				});
			} else {
				redirect(RouteUri.MANAGEMENT_HOME);
			}
		} else {
			openSignupPopup();
		}
	}, [currAcc, openSignupPopup, enqueueSnackbar, redirect]);

	useEffect(() => {
		if (currAcc || !searchParams.has('showSignup')) {
			return;
		}
		openSignupPopup();
		searchParams.delete('showSignup');
		setSearchParams(searchParams, {
			replace: true,
		});
	}, [currAcc, searchParams, setSearchParams, openSignupPopup]);

	return (
		<NavTemplate
			backgroundColor={theme.palette.light4.main}
			fullscreen
			TopBarProps={{
				transparentUntilScroll: true,
				sx: {
					borderStyle: 'none',
				},
			}}
			pageTitle='For Employers'
			pageDescription='Ivy Clinicians is the emergency medicine job board that connects you with physicians, nurse practitioners, and physician assistants interested in working with your practice.'
			showSupportLauncher
			showFooter
			pathIsCanonical
		>
			<DataLoader
				variant='logo'
				fullscreen
				data={data}
				loading={loading}
				error={error}
			>
				{() => (
					<>
						<Hero
							sx={{
								height: {
									xs: 'calc(100vh - 64px)',
									mobileMenu: 'calc(100vh - 104px)',
								},
								minHeight: {
									xs: MIN_HEIGHT_MOBILE - 64,
									mobileMenu: MIN_HEIGHT_DESKTOP - 64,
								},
							}}
							onClickCta={handleClickCta}
						/>
						<FreePlan
							sx={{
								pt: 15,
							}}
							onClickCta={handleClickCta}
						/>
						<JobPostingPlans jobPostingData={data?.jobPostingPrices} />
						{/*<Suspense fallback={<DataLoader variant='circular' loading />}>*/}
						{/*	<StateImpressions />*/}
						{/*</Suspense>*/}
						<Testimonials />
					</>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default ForEmployers;
